"use client";

import Button from "@/components/ui/Button";
import { Checkbox, CheckboxField } from "@/components/ui/Checkbox";
import InnerHTML from "@/components/ui/InnerHTML";
import { InputField } from "@/components/ui/Input";
import { useNewsletterMutation } from "@/hooks/useNewsletterMutation";
import useLocation from "@/lib/centra/hooks/useLocation";
import { useRegister } from "@/lib/centra/hooks/useSelection";
import { useValidation } from "@/lib/centra/hooks/useValidation";
import { useRouter } from "@/navigation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslations } from "next-intl";
import { default as Link } from "next/link";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styles from "../forms.module.css";

export const RegisterForm = () => {
  const { signUp } = useRegister();
  const location = useLocation();
  const router = useRouter();
  const validate = useValidation();
  const t = useTranslations();
  const mutation = useNewsletterMutation();
  const [newsletter, setNewsletter] = useState(false);

  const schema = validate.object({
    email: validate.string().email().required(),
    firstName: validate.string().required(),
    lastName: validate.string().required(),
    password: validate.string().required(),
    confirmPassword: validate.string(),
    newsletter: validate.boolean().optional(),
  });

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (data) => {
    signUp.mutate(data, {
      onSuccess: async () => {
        if (!!newsletter) {
          mutation.mutate({
            email: data.email,
            country: location?.location || "EN",
          });
        }
        router.push("/account");
      },
      onError: (error) => {
        console.error("Sign-up failed:", error);
      },
    });
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} className={styles.form}>
        {signUp.error && (
          <p className={styles.error}>
            {signUp.error?.message ? (
              <InnerHTML html={signUp.error.message} />
            ) : (
              t("myAccount.registerPage.error")
            )}
          </p>
        )}
        <div className={styles.inputs}>
          <div className={styles.inputRow}>
            <InputField
              label={t("myAccount.registerPage.firstNameLabel")}
              name="firstName"
              required
            />
            <InputField
              label={t("myAccount.registerPage.lastNameLabel")}
              name="lastName"
              required
            />
          </div>
          <InputField
            label={t("myAccount.registerPage.emailLabel")}
            name="email"
            required
          />
          <InputField
            label={t("myAccount.registerPage.passwordLabel")}
            type="password"
            autoComplete="new-password"
            name="password"
            required
          />
        </div>
        <div className={styles.confirmation}>
          <CheckboxField
            label={t.rich("myAccount.registerPage.privacyCheckbox", {
              link: (chunks) => (
                <Link href={"/practical-information/privacy-policy"}>
                  {chunks}
                </Link>
              ),
            })}
            name="remember"
            required
          />
        </div>
        <div className={styles.confirmation}>
          <Checkbox
            label={t("myAccount.registerPage.newsletterCheckbox")}
            name="newsletter"
            checked={newsletter}
            onChange={() => setNewsletter(!newsletter)}
          />
        </div>
        <Button
          className={styles.button}
          isLoading={signUp.isPending}
          type="submit"
        >
          {t("myAccount.registerPage.submit")}
        </Button>
      </form>
    </FormProvider>
  );
};
