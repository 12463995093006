import { setLocalSubscribedState } from "@/components/blocks/atoms/NewsletterPopup/NewsletterPopup";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import { useMutation } from "@tanstack/react-query";

export const useNewsletterMutation = () => {
  const token = usePersistedStore((store) => store.token);

  const mutation = useMutation({
    mutationFn: async ({
      email,
      country,
    }: {
      email: string;
      country: string;
    }) => {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API}/newsletter-subscription`,
        {
          method: "POST",
          headers: {
            "API-Token": token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, country }),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to subscribe to the newsletter");
      }

      setLocalSubscribedState({
        closedCount: 0,
        closedAt: "",
        hasSubscribed: true,
      });

      return response.json();
    },
  });
  return mutation;
};
